import React from 'react'
import SectionWithBackground from './SectionComponent'
import MLC from '../Assts/MyLeading_Campus.png'

const Contactbanner = () => {
  return (
     <SectionWithBackground
        title="Conatct Us"
        breadcrumbs={[
            {label: "Home" , link: "/home"},
            {label: "Contact Us" , link: ""}
        ]}
        backgroundImage={MLC}
     />
  )
}

export default Contactbanner