import React from 'react'
import SectionWithBackground from './SectionComponent'
import MLC from '../Assts/MyLeading_Campus.png'

const Clientbanner = () => {
  return (
    <SectionWithBackground
     title="Client"
     breadcrumbs={[
        {label: "Home", link: '/home'},
        {label: 'Client',link: ""}
     ]}
     backgroundImage={MLC}
    />
  )
}

export default Clientbanner