import React from 'react'
import SectionWithBackground from './SectionComponent'
import MLC from '../Assts/MyLeading_Campus.png'

const FeaturesBanner = () => {
  return (
     <SectionWithBackground
        title="Features"
        breadcrumbs={[
            {label: "Home", link: '/home'},
            {label: "Features", link: ''}
        ]}
        backgroundImage={MLC}
     />
  )
}

export default FeaturesBanner