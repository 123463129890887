import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Features from './Pages/Features';
import Solution from './Pages/Solution';
import Clients from './Pages/Clients';
import Testimonials from './Pages/Testimonials';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Blog from './Pages/Blog';
import ContactModal from './Components/ContactModal';
import TermsAndConditions from './Pages/TermsAndConditions';
import RefundPolicy from './Pages/RefundPolicy';
import { Toaster } from 'react-hot-toast';

export const AppContext = React.createContext();


function App() {
  const [open, setOpen] = React.useState(false);
  return (
    <AppContext.Provider value={{ open, setOpen }}>
      <ContactModal openModal={open} setOpenModal={setOpen} />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/features" element={<Features />}></Route>
          <Route path="/solution" element={<Solution />}></Route>
          <Route path="/clients" element={<Clients />}></Route>
          <Route path="/testimonials" element={<Testimonials />}></Route>
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/contact" element={<ContactUs />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path='/terms' element={<TermsAndConditions />}></Route>
          <Route path='/refund' element={<RefundPolicy />}></Route>
        </Routes>
      </Router>
      <Toaster />
    </AppContext.Provider>
  );
}

export default App;
